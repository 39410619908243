import { useState, useEffect } from "react";

import api from "../../services/api";

export function BannerInstitucional() {

    const [banners, setBanners] = useState([]);

    useEffect(() => {
        async function loadBanners() {
            const response = await api.get('banners-institucional');
            setBanners(response.data);   
        }
        loadBanners();
    }, []);

    return (
        <>
            {banners.map(banner => (
                <option key={banner.id} value={banner.id}>{banner.title}</option>
            ))}
        </>
    );
}