import { Routes } from './routes';

import { MainContextProvider } from './contexts/MainContext';

import { GlobalStyle } from "./styles/global";

export function App() {
  return (
    <MainContextProvider>
      <div className="App">
        <Routes />
        <GlobalStyle />
      
      </div>
    </MainContextProvider>
  );
}

