import { List } from './styles';
import { Link } from 'react-router-dom';

import menu1 from '../../assets/AlterarBanner.svg';
import menu2 from '../../assets/Listar.svg';


export function MenuAconteceNoParque(){
  return (
    <>
      <List>
        <Link to="/acontece-no-parque-banner">
          <img src={menu1} alt="Alterar Banner" />
          <p>Alterar Banner</p>
        </Link>
      </List>
      <List>
        <Link to="/acontece-no-parque-lista-eventos">
          <img src={menu2} alt="Lista de Eventos" />
          <p>Lista de Eventos</p>
        </Link>
      </List>
    </>
    
  );
}