import { decodeToken } from 'react-jwt'
import { IoMdArrowDropright } from 'react-icons/io'
import { Link } from 'react-router-dom'

import logo from '../../assets/logo.png'

import { Title, Container, Menu, TopBar } from './styles'

import api from '../../services/api'

export function Header(props) {
 async function handleLogout() {


  await api.post('/logout');

  localStorage.removeItem('token')

 }

 const token = localStorage.getItem('token')

 const { login } = decodeToken(token)


 return (
  <>
   <TopBar>
    {props.title && props.subtitle ? (
     <>
      <Title>
       {props.title} <IoMdArrowDropright color="#ffffff" size={14} />
       <strong>{props.subtitle}</strong>
      </Title>
     </>
    ) : (
     <Title>{props.title}</Title>
    )}

    <button onClick={handleLogout}>
     <Link to="/">Sair</Link>
    </button>
   </TopBar>
   <Container>
    <Link to="/home">
     <img src={logo} alt="Shopping Parque da Cidade" />
    </Link>

    <Menu>
     <div className="login">
      Olá, <strong>{login}</strong>
     </div>
     <h2>
      <Link to="/home">Conteúdo</Link>
     </h2>

     <ul>
      <li>
       <Link to="/pagina-inicial">Página Inicial</Link>
      </li>
      <li>
       <Link to="/segmentos">Segmentos</Link>
      </li>
      <li>
       <Link to="/lojas">Lojas</Link>
      </li>
      <li>
       <Link to="/alimentacao">Alimentação</Link>
      </li>
      <li>
       <Link to="/cinema">Cinema</Link>
      </li>
      <li>
       <Link to="/acontece-no-parque">Acontece no Parque</Link>
      </li>
      <li>
       <Link to="/tem-no-parque">Tem no Parque</Link>
      </li>
      <li>
       <Link to="/clube-do-parque">Clube do Parque</Link>
      </li>
      <li>
       <Link to="/institucional">Institucional</Link>
      </li>
      <li>
       <Link to="/servicos">Serviços</Link>
      </li>
      <li>
       <Link to="/contato">Contato</Link>
      </li>
     </ul>

     <h2>
      <Link to="/usuarios">Usuários</Link>
     </h2>
    </Menu>
   </Container>
  </>
 )
}
