import { Header } from "../../components/Header";
import { MenuInstitucional } from "../../components/MenuInstitucional";

import { Container } from './styles';

export function Institucional(props) {

  const title = "Institucional";

  return (
    <>
      <Header title={title} />

      <Container>
        <MenuInstitucional />
      </Container>
      
    </>
  );
}