import { Header } from "../../components/Header";
import { MenuGastronomia } from "../../components/MenuGastronomia";

import { Container } from './styles';

export function Gastronomia(props) {

  const title = "Alimentação";
  
  return (
    <>
      <Header title={title} />

      <Container>
        <MenuGastronomia />
      </Container>
      
    </>
  );
}