import styled from 'styled-components';

import bg from '../../assets/login-bg.png';

export const Background = styled.div`
  background: url(${bg}) center bottom no-repeat;
  background-size: cover;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;

  img {
    width: 100%;
    max-width: 230px;
    margin: 0 auto;
    margin-bottom: 2rem;
    display: block;
  }

  input {
    width: 100%;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--title);
    padding: 0 0.625rem;
    height: 4rem;
    background: transparent;
    outline: none;
    color: var(--white);


    font-weight: 400;
    font-size: 1rem;

    &:focus {
      border-bottom: 1px solid var(--white);

      &::placeholder {
        color: var(--white);
        font-weight: 700
      }
    }

    &::placeholder {
      color: var(--title);
      font-weight: 700
    }

    & + input {
      margin-top: 1rem;
    }
  }

  button[type="submit"] {
    width: 100%;
    padding: 0 0.625rem;
    height: 4rem;
    background: var(--green);
    color: var(--white);
    border-radius: 0;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.5rem;
    text-transform: uppercase;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

  }
`;