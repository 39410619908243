import { Header } from "../../components/Header";
import { MenuHome } from "../../components/MenuHome";

import { Container } from './styles';

export function Home() {

  const title = "Home";

  return (
    <>
      <Header title={title} />

      <Container>
        <MenuHome />
      </Container>
      
    </>
  );
}