import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { DropContainer, UploadMessage } from './styles'

export function Upload(props) {
 const [selectedFileUrl, setSelectedFileUrl] = useState('')

 const renderDragMessage = (isDragActive, isDragReject) => {
  if (!isDragActive) {
   return <UploadMessage>Arraste e jogue ou clique aqui</UploadMessage>
  }

  if (isDragReject) {
   return <UploadMessage type="error">Arquivo não suportado</UploadMessage>
  }

  return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
 }

 const { onUpload } = props

 const onDrop = useCallback(
  acceptedFiles => {
   const file = acceptedFiles[0]
   const fileurl = URL.createObjectURL(file)

   setSelectedFileUrl(fileurl)
   onUpload(file)
  },
  [onUpload]
 )
 const { getRootProps, getInputProps, isDragActive, isDragReject } =
  useDropzone({
   onDrop,
   accept: 'image/*'
  })

 return (
  <DropContainer
   {...getRootProps()}
   isDragActive={isDragActive}
   isDragReject={isDragReject}
  >
   <input {...getInputProps()} name="file" />
   <input {...getInputProps()} name="file_mobile" />

   {selectedFileUrl ? (
    <img src={selectedFileUrl} alt="Thumbnail" />
   ) : (
    renderDragMessage(isDragActive, isDragReject)
   )}
  </DropContainer>
 )
}
