import { toast } from 'react-toastify';

import { IoMdClose } from 'react-icons/io';
import { FaTrashAlt } from 'react-icons/fa';

import api from "../../services/api";

import { ModalBG, ModalContainer, Title, Subtitle, Content, ModalButton } from './styles';

export function ModalDelete(props){

    const route = props.name;

    const token = localStorage.getItem('token');

    async function handleDelete(e) {
        e.preventDefault();

        try {

            const response = await api.delete(`/${route}/delete/${props.id}`, 
                {
                    headers: {
                        Accept: 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': true,
                        'Access-Control-Allow-Headers': 'Origin, X-Requeted-With, Content-Type, Accept, Authorization, RBR',
                        'Content-Type': 'application/json', 
                        'Authorization': `Bearer ${token}`
                    } 
                }
            );

            const message = await response.data.message;    

            if(message) {
                toast.warning(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else {

                props.isClose();

                toast.success(response.data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });      

                props.isLoad();
                
            }
      
        } catch (error) {
            console.log(error);
        }
        
    }

    return (
        <>
            <ModalBG>
                <ModalContainer>
                    <span><IoMdClose size={25} color="#63a269" onClick={props.isClose} /></span>
                    <Title>
                        <FaTrashAlt size={40} color="#63a269" />
                        <br /><br />
                        Voce tem certeza? 
                    </Title>
                    <Subtitle>
                        Tem certeza que deseja deletar o {props.title} {props.id} ?
                    </Subtitle>
                    <Content>
                        <ModalButton className="cancelar" onClick={props.isClose}>Cancelar</ModalButton>
                        <ModalButton className="deletar" onClick={handleDelete}>Deletar</ModalButton>
                    </Content>
                </ModalContainer>
            </ModalBG>
        </>
    );
}