import styled from 'styled-components';

export const Button = styled.button`
    width: 70px;
    height: 70px;
    background: var(--title);
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    border: 0;
    border-radius: 50%;
    outline: none;

    transition: filter 0.2s;

    &:hover {
        filter: brightness(0.9)
    }
`;