import { Header } from "../../components/Header";
import { MenuLojas } from "../../components/MenuLojas";

import { Container } from './styles';

export function Lojas(props) {
  
  const title = "Lojas";

  return (
    <>
      <Header title={title} />

      <Container>
        <MenuLojas />
      </Container>
      
    </>
  );
}