import { useEffect, useMemo, useCallback } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReactPaginate from 'react-paginate'
import { useMain } from '../../contexts/MainContext'

import api from '../../services/api'

import { Header } from '../../components/Header'
import { ModalAdd } from '../../components/ModalAdd'
import { ModalEdit } from '../../components/ModalEdit'
import { ModalDelete } from '../../components/ModalDelete'
import { Botao } from '../../components/Botao'

import Edit from '../../assets/edit.svg'
import Checked from '../../assets/checked.svg'
import Unchecked from '../../assets/unchecked.svg'
import Trash from '../../assets/trash.svg'

import banner4 from '../../assets/banner4.png'

import { Container, Title, Content, Table } from './styles'

export function ListaRestaurantes() {
 const title = 'Alimentação'
 const subtitle = 'Lista de Restaurantes'

 const fields = useMemo(
  () => [
   {
    id: 'Restaurantes',
    imagem: String,
    name: String,
    category: String,
    subcategory: String,
    text: String,
    floor: String,
    phone: String,
    site: String,
    instagram: String,
    facebook: String,
    whatsapp: String,
    status: Number
   }
  ],
  []
 )

 const {
  restaurantes,
  setRestaurantes,
  modalAdd,
  toggleModalAdd,
  modalEdit,
  toggleModalEdit,
  modalDelete,
  toggleModalDelete,
  id,
  setID,
  offset,
  limit,
  handlePageClick
 } = useMain()

 const pageCount = Math.ceil(restaurantes.length / limit)

 const loadRestaurantes = useCallback(async () => {
  try {
   const response = await api.get('alimentacao')

   setRestaurantes(response.data)
  } catch (error) {
   console.log('Ocorreu um erro ao buscar as lojas')
  }
 }, [setRestaurantes])

 useEffect(() => {
  loadRestaurantes()
 }, [loadRestaurantes])

 return (
  <>
   <ToastContainer />

   {modalAdd && (
    <ModalAdd
     isClose={toggleModalAdd}
     isLoad={loadRestaurantes}
     fields={fields}
     name="alimentacao"
     title="Restaurante"
    />
   )}
   {modalDelete && (
    <ModalDelete
     isClose={toggleModalDelete}
     isLoad={loadRestaurantes}
     id={id}
     name="alimentacao"
     title="Restaurante"
    />
   )}
   {modalEdit && (
    <ModalEdit
     isClose={toggleModalEdit}
     isLoad={loadRestaurantes}
     id={id}
     name="alimentacao"
     folder="restaurantes"
     title="Restaurante"
    />
   )}

   <Header title={title} subtitle={subtitle} />

   <Container>
    <Title>Gerenciador de restaurantes</Title>
    {restaurantes.length <= 0 ? (
     <>Nenhum restaurante cadastrado.</>
    ) : (
     <>
      <Table>
       <thead>
        <tr>
         <th>Exibição</th>
         <th>Nome do restaurante</th>
         <th>Imagem</th>
         <th>Categoria</th>
         <th>Piso</th>
         <th>Telefone</th>
         <th>Site</th>
         <th>Status</th>
         <th colSpan="2"></th>
        </tr>
       </thead>
       <tbody>
        {restaurantes &&
         restaurantes.slice(offset, offset + limit).map(restaurante => (
          <tr key={restaurante.id}>
           <td>{restaurante.id}</td>
           <td>{restaurante.name}</td>
           <td>
            <div className="lojas-logo">
             {restaurante.file ? (
              <img
               src={`${process.env.REACT_APP_IMAGE_URL}/uploads/restaurantes/${restaurante.file}`}
               alt={restaurante.name}
              />
             ) : (
              ''
             )}
            </div>
           </td>
           <td>{restaurante.category}</td>
           <td>{restaurante.floor}</td>
           <td>{restaurante.phone}</td>
           <td>{restaurante.site}</td>
           <td>
            {restaurante.status === 1 ? (
             <img src={Checked} alt="Ativo" />
            ) : (
             <img src={Unchecked} alt="Inativo" />
            )}
           </td>
           <td onMouseDown={() => setID(restaurante.id)}>
            <img
             src={Edit}
             alt="Editar"
             title="Editar"
             onClick={toggleModalEdit}
            />
           </td>
           <td onMouseDown={() => setID(restaurante.id)}>
            <img
             src={Trash}
             alt="Deletar"
             title="Deletar"
             onClick={toggleModalDelete}
            />
           </td>
          </tr>
         ))}
       </tbody>
      </Table>

      <ReactPaginate
       previousLabel={'<'}
       nextLabel={'>'}
       pageCount={pageCount}
       onPageChange={handlePageClick}
       containerClassName={'pagination'}
       previousLinkClassName={'pagination__link'}
       nextLinkClassName={'pagination__link'}
       disabledClassName={'pagination__link--disabled'}
       activeClassName={'pagination__link--active'}
      />
     </>
    )}
   </Container>

   <Container>
    <Title>Especificações dos logos</Title>
    <Content>
     <div>
      <img src={banner4} alt="Logo" />
      <h3>Logo</h3>
      <p>Formato: 500 x 500 pixels</p>
     </div>
    </Content>
   </Container>

   <Botao isOpen={toggleModalAdd} />
  </>
 )
}
