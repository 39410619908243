import { useEffect, useMemo, useCallback } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from 'react-paginate';
import { useMain } from '../../contexts/MainContext';

import api from "../../services/api";

import { Header } from "../../components/Header";
import { ModalAdd } from "../../components/ModalAdd";
import { ModalEdit } from "../../components/ModalEdit";
import { ModalDelete } from "../../components/ModalDelete";
import { Botao } from "../../components/Botao";

import Edit from '../../assets/edit.svg';
import Checked from '../../assets/checked.svg';
import Unchecked from '../../assets/unchecked.svg';
import Trash from '../../assets/trash.svg'; 

import { Container, Title, Table } from './styles';

export function Contato() {

    const title = "Contato";

    const fields = useMemo(() => 
        [
            {
               id: "Contato",
               title: String,
               text: String,
               status: Number
            }
        ],
        []
    );

    const {
        contato, 
        setContato,
        modalAdd,  
        toggleModalAdd,
        modalEdit,
        toggleModalEdit,
        modalDelete,
        toggleModalDelete,
        id,
        setID,
        offset, 
        limit, 
        handlePageClick 
    } = useMain();

    const pageCount = Math.ceil(contato.length / limit);
    
    const loadContato = useCallback( async () => {

        try {
            const response = await api.get('contato');

            setContato(response.data);    

        } catch (error) {
            console.log("Ocorreu um erro ao buscar as informações necessárias");
        }
        
    }, [setContato]);

    useEffect(() => {
        loadContato();
    }, [loadContato]);

    

    return (
        <>
            <ToastContainer />

            {modalAdd && (<ModalAdd isClose={toggleModalAdd} isLoad={loadContato} fields={fields} name="contato" title="Contato" />)}
            {modalDelete && (<ModalDelete isClose={toggleModalDelete} isLoad={loadContato} id={id} name="contato" title="Contato" />)}
            {modalEdit && (<ModalEdit isClose={toggleModalEdit} isLoad={loadContato} id={id} name="contato" title="Contato" /> )}

            <Header title={title} />

            <Container>
                <Title>Gerenciador de Informações Pagina de Contato</Title>
                {contato.length <= 0 ? (
                    <>
                        Nenhuma informação cadastrada.
                    </>
                ) : (
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Exibição</th>
                                    <th>Título</th>
                                    <th>Texto do serviço</th>
                                    <th>Status</th>
                                    <th colSpan="2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {contato && (
                                    contato.slice(offset, offset + limit).map(servico => ( 
                                        <tr key={servico.id}>
                                            <td>{servico.id}</td>
                                            <td>{servico.title}</td>
                                            <td>{servico.text.replace(/<[^>]*>/g, '')}</td>
                                            <td>{servico.status === 1 ? <img src={Checked} alt="Ativo" />  : 
                                                <img src={Unchecked} alt="Inativo" />}</td>
                                            <td onMouseDown={() => setID(servico.id)}>
                                                <img src={Edit} alt="Editar" title="Editar" onClick={toggleModalEdit} />
                                            </td>
                                            <td onMouseDown={() => setID(servico.id)}>
                                                <img src={Trash} alt="Deletar" title="Deletar" onClick={toggleModalDelete} />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>

                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </>
                )}
            </Container>

            <Botao isOpen={toggleModalAdd} />

        </>
    );
}
