import styled, { css } from "styled-components";

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone"
})`
  border: 1px dashed var(--green);
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  padding: 0 !important;
  width: 100%;
  height: 16.5rem;
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
  &:focus {
      outline: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover
  }
`;

const messageColors = {
  default: "#63a269",
  error: "#e57878",
  success: "#78e5d5"
};

export const UploadMessage = styled.p`
  display: flex;
  color: ${props => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  height: 100%
`;