import { useEffect, useMemo, useCallback } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from 'react-paginate';
import { useMain } from '../../contexts/MainContext';

import api from "../../services/api";

import { Header } from "../../components/Header";
import { ModalAdd } from "../../components/ModalAdd";
import { ModalEdit } from "../../components/ModalEdit";
import { ModalDelete } from "../../components/ModalDelete";
import { Botao } from "../../components/Botao";

import Edit from '../../assets/edit.svg';
import Checked from '../../assets/checked.svg';
import Unchecked from '../../assets/unchecked.svg';
import Trash from '../../assets/trash.svg'; 

import { Container, Title, Table } from './styles';

export function ListaSegmentos(props) {

    const title = "Segmentos";

    const fields = useMemo(() => 
        [
            {
               id: "Lista de Segmentos",
               name: String,
               segmento: String,
               status: Number
            }
        ],
        []
    );

    const {
        segmentos, 
        setSegmentos,
        modalAdd,  
        toggleModalAdd,
        modalEdit,
        toggleModalEdit,
        modalDelete,
        toggleModalDelete,
        id,
        setID,
        offset, 
        limit, 
        handlePageClick 
    } = useMain();

    const pageCount = Math.ceil(segmentos.length / limit);

    const loadSegmentos = useCallback( async () => {    

        try {
            const response = await api.get('segmento');

            setSegmentos(response.data);    

        } catch (error) {
            console.log("Ocorreu um erro ao buscar os segmentos");
        }
    
    }, [setSegmentos]);

    useEffect(() => {
        loadSegmentos();
    }, [loadSegmentos]);


    return (
        <>

            <ToastContainer />

            {modalAdd && (<ModalAdd isClose={toggleModalAdd} isLoad={loadSegmentos} fields={fields} name="segmento" title="Segmento" />)}
            {modalDelete && (<ModalDelete isClose={toggleModalDelete} isLoad={loadSegmentos} id={id} name="segmento" title="Segmento" />)}
            {modalEdit && (<ModalEdit isClose={toggleModalEdit} isLoad={loadSegmentos} id={id} name="segmento" title="Segmento" /> )}

            <Header title={title} />
            <Container>
                <Title>Gerenciador de segmentos</Title>
                {segmentos.length <= 0 ? (
                    <>
                        Nenhum segmento cadastrado.
                    </>
                ) : (
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Exibição</th>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    <th colSpan="2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {segmentos && (
                                    segmentos.slice(offset, offset + limit).map(segmento => ( 
                                        <tr key={segmento.id}>
                                            <td>{segmento.id}</td>
                                            <td>{segmento.name}</td>
                                            <td>{segmento.status === 1 ? <img src={Checked} alt="Ativo" />  : 
                                                <img src={Unchecked} alt="Inativo" />}</td>
                                            <td onMouseDown={() => setID(segmento.id)}>
                                                <img src={Edit} alt="Editar" title="Editar" onClick={toggleModalEdit} />
                                            </td>
                                            <td onMouseDown={() => setID(segmento.id)}>
                                                <img src={Trash} alt="Deletar" title="Deletar" onClick={toggleModalDelete} />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>

                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </>
                )}

               
            </Container>

            <Botao isOpen={toggleModalAdd} />


        </>
    );
}
