import { FiPlus } from 'react-icons/fi'

import { Button } from './styles'

export function Botao(props) {
  return (
    <Button onClick={props.isOpen}>
      <FiPlus color="#fff" size={60} />
    </Button>
  )
}
