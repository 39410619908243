import styled from 'styled-components'

export const ModalBG = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #00000066;
  z-index: 999;
  overflow-y: auto;
`

export const ModalContainer = styled.div`
  position: relative;
  top: 25px;
  width: 800px;
  height: auto;
  background: var(--white-bg);
  border: 1px solid var(--green);
  margin: 0 auto;
`

export const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 0.9rem;
  color: var(--green);
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4rem;
  padding: 1rem 0.7rem;

  svg {
    cursor: pointer;
  }
`

export const Content = styled.form`
  div {
    padding: 0 0.7rem 1rem 0.7rem;
    font-size: 0.9rem;

    label {
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--subtitle);
      display: block;
      padding-bottom: 0.7rem;
    }

    select {
      width: 100%;
      height: 2rem;
      border: 1px solid var(--green);
    }

    input[type='text'],
    input[type='password'] {
      width: 100%;
      outline: none;

      color: var(--black);
      font-size: 1rem;
      font-weight: 400;
      border: 0;
      border-bottom: 1px solid var(--green);
    }

    input[type='checkbox'] {
      -moz-appearance: none;
      -webkit-appearance: none;
      -o-appearance: none;
      outline: none;
      content: none;
    }

    input[type='checkbox']:before {
      content: '✔';
      font-size: 13px;
      color: transparent !important;
      background: var(--white);
      display: block;
      width: 15px;
      height: 15px;
      border: 1px solid var(--green);
      margin-right: 0.2rem;
    }

    input[type='checkbox']:checked:before {
      color: var(--white) !important;
      background: var(--green);
      display: block;
      text-align: center;
    }
  }

  .btn-submit {
    margin: 3rem 0 0 0;
  }

  .Loja img,
  .Restaurante img,
  .Benefício img,
  .Serviços img {
    width: 30%;
    display: block;
    margin: 0 auto;
  }

  .Filme img {
    width: 20%;
    display: block;
    margin: 0 auto;
  }

  .first {
    padding: 0;
  }
`

export const Dropzone = styled.div`
  width: 100%;
  height: 100px;
  border: 1px solid var(--green);
`

export const ModalButton = styled.button`
  background: var(--green);
  color: var(--white);

  width: 100%;
  border: 0;
  height: 3rem;

  font-size: 1rem;
  font-weight: 700;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`
