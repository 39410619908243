import styled from 'styled-components';


export const Container = styled.div`
  max-width: 1600px;
  margin: 4rem 0 0 15rem;
  padding: 0 0.9375rem;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;

`;