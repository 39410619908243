import { isAuthenticated } from './services/auth'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { Login } from './pages/Login'
import { Home } from './pages/Home'
import { PaginaInicial } from './pages/PaginaInicial'
import { HomeSlides } from './pages/HomeSlides'
import { HomeBanners } from './pages/HomeBanners'
import { ListaSegmentos } from './pages/ListaSegmentos'
import { Lojas } from './pages/Lojas'
import { LojaBanner } from './pages/LojaBanner'
import { ListaLojas } from './pages/ListaLojas'
import { Gastronomia } from './pages/Gastronomia'
import { GastronomiaBanner } from './pages/GastronomiaBanner'
import { ListaRestaurantes } from './pages/ListaRestaurantes'
import { Cinema } from './pages/Cinema'
import { CinemaBanner } from './pages/CinemaBanner'
import { CinemaContent } from './pages/CinemaContent'
import { AconteceNoParque } from './pages/AconteceNoParque'
import { AconteceNoParqueBanner } from './pages/AconteceNoParqueBanner'
import { ListaEventos } from './pages/ListaEventos'
import { TemNoParque } from './pages/TemNoParque'
import { TemNoParqueBanner } from './pages/TemNoParqueBanner'
import { TemNoParqueContent } from './pages/TemNoParqueContent'
import { TemNoParqueDestaque } from './pages/TemNoParqueDestaque'
import { Institucional } from './pages/Institucional'
import { InstitucionalBanner } from './pages/InstitucionalBanner'
import { InstitucionalContent } from './pages/InstitucionalContent'
import { InstitucionalHorarios } from './pages/InstitucionalHorarios'
import { ClubeDoParque } from './pages/ClubeDoParque'
import { ClubeDoParqueBanner } from './pages/ClubeDoParqueBanner'
import { ListaBeneficios } from './pages/ListaBeneficios'
import { Servicos } from './pages/Servicos'
import { Contato } from './pages/Contato'
import { Usuarios } from './pages/Usuarios'
import { TemNoParqueGalerias } from './pages/TemNoParqueGalerias'

const PrivateRoute = ({ component: Component, ...rest }) => (
 <Route
  {...rest}
  render={props =>
   isAuthenticated() ? (
    <Component {...props} />
   ) : (
    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
   )
  }
 />
)

export function Routes() {
 return (
  <BrowserRouter>
   <Switch>
    <Route path="/" exact component={Login} />
    <PrivateRoute path="/home" component={Home} />
    <PrivateRoute path="/pagina-inicial" component={PaginaInicial} />
    <PrivateRoute path="/slides" component={HomeSlides} />
    <PrivateRoute path="/banners" component={HomeBanners} />
    <PrivateRoute path="/segmentos" component={ListaSegmentos} />
    <PrivateRoute path="/lojas" component={Lojas} />
    <PrivateRoute path="/lojas-banner" component={LojaBanner} />
    <PrivateRoute path="/lista-de-lojas" component={ListaLojas} />
    <PrivateRoute path="/alimentacao" component={Gastronomia} />
    <PrivateRoute path="/alimentacao-banner" component={GastronomiaBanner} />
    <PrivateRoute path="/lista-de-restaurantes" component={ListaRestaurantes} />
    <PrivateRoute path="/cinema" component={Cinema} />
    <PrivateRoute path="/cinema-banner" component={CinemaBanner} />
    <PrivateRoute path="/cinema-conteudo" component={CinemaContent} />
    <PrivateRoute path="/acontece-no-parque" component={AconteceNoParque} />
    <PrivateRoute
     path="/acontece-no-parque-banner"
     component={AconteceNoParqueBanner}
    />
    <PrivateRoute
     path="/acontece-no-parque-lista-eventos"
     component={ListaEventos}
    />
    <PrivateRoute path="/tem-no-parque" component={TemNoParque} />
    <PrivateRoute path="/tem-no-parque-banner" component={TemNoParqueBanner} />
    <PrivateRoute
     path="/tem-no-paque-conteudo"
     component={TemNoParqueContent}
    />
    <PrivateRoute
     path="/tem-no-paque-destaque"
     component={TemNoParqueDestaque}
    />
    <PrivateRoute
     path="/tem-no-paque-galerias"
     component={TemNoParqueGalerias}
    />
    <PrivateRoute path="/institucional" component={Institucional} />
    <PrivateRoute
     path="/institucional-banner"
     component={InstitucionalBanner}
    />
    <PrivateRoute
     path="/institucional-conteudo"
     component={InstitucionalContent}
    />
    <PrivateRoute
     path="/institucional-horarios"
     component={InstitucionalHorarios}
    />
    <PrivateRoute path="/clube-do-parque" component={ClubeDoParque} />
    <PrivateRoute
     path="/clube-do-parque-banner"
     component={ClubeDoParqueBanner}
    />
    <PrivateRoute
     path="/clube-do-parque-lista-eventos"
     component={ListaBeneficios}
    />
    <PrivateRoute path="/servicos" component={Servicos} />
    <PrivateRoute path="/contato" component={Contato} />
    <PrivateRoute path="/usuarios" component={Usuarios} />
   </Switch>
  </BrowserRouter>
 )
}
