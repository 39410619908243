import styled from 'styled-components';



export const List = styled.div`

  background: var(--white-bg);
  width: 230px;
  height: 11rem;
  padding: 2rem;
  border-radius: 1rem;
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 3rem;

  a {
    text-decoration: none;
  }

  img {
    width: 4.1rem;
    height: 6rem;
    margin: 0 auto;
    padding: 0 0 1.2rem 0;
    display: block;
  }

  p {
    color: var(--title);
    text-transform: uppercase;
    text-align: center;
  }

  &:hover {
    border: 3px solid var(--green);
  }
`;