import styled from 'styled-components'

export const Title = styled.h2`
 font-size: 1.1rem;
 color: var(--white);
 font-weight: 400;
 text-transform: uppercase;

 svg {
  margin: 0 0.3rem;
 }
`

export const TopBar = styled.div`
 background: var(--green);
 height: 4rem;
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 0 1.25rem 0 14rem;
 /* position: fixed;
  top: 0;
  width: 100%; */

 button {
  color: var(--white);
  background: transparent;
  border-radius: 50px;
  border: 1px solid var(--white);
  padding: 0.5rem 1.5625rem;
  text-transform: uppercase;
  outline: none;
  font-weight: 700;

  a {
   text-decoration: none;
   color: var(--white);
  }
 }
`

export const Container = styled.div`
 position: fixed;
 top: 0;
 width: 200px;
 height: 100vh;
 background: var(--white-bg);
 -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
 -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
 box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);

 img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  padding: 2rem 0rem;
  display: block;
 }
`

export const Menu = styled.div`
 padding: 2rem 1rem;

 h2 {
  color: var(--title);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin: 1rem 0;
  cursor: pointer;

  a {
   text-decoration: none;
   color: var(--title);
  }
 }

 ul {
  list-style: none;
 }

 li {
  & + li {
   margin-top: 0.8rem;
  }

  &:last-of-type {
   margin-bottom: 3rem;
  }

  a {
   color: var(--subtitle);
   font-weight: 400;
   font-size: 0.875rem;
   text-decoration: none;
   cursor: pointer;
  }

  a:hover {
   color: var(--title);
   font-weight: 700;
  }
 }
`
