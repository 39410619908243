import { useEffect, useMemo, useCallback } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReactPaginate from 'react-paginate'
import { useMain } from '../../contexts/MainContext'

import api from '../../services/api'

import { Header } from '../../components/Header'
import { ModalAdd } from '../../components/ModalAdd'
import { ModalEdit } from '../../components/ModalEdit'
import { ModalDelete } from '../../components/ModalDelete'
import { Botao } from '../../components/Botao'

import Edit from '../../assets/edit.svg'
import Checked from '../../assets/checked.svg'
import Unchecked from '../../assets/unchecked.svg'
import Trash from '../../assets/trash.svg'

import banner4 from '../../assets/banner4.png'

import { Container, Title, Content, Table } from './styles'

export function TemNoParqueDestaque() {
 const title = 'Tem no Parque'
 const subtitle = 'Alterar banners de Destaques'

 const fields = useMemo(
  () => [
   {
    id: 'Eventos em Destaque',
    imagem: String,
    title: String,
    status: Number
   }
  ],
  []
 )

 const {
  destaques,
  setDestaques,
  modalAdd,
  toggleModalAdd,
  modalEdit,
  toggleModalEdit,
  modalDelete,
  toggleModalDelete,
  id,
  setID,
  offset,
  limit,
  handlePageClick
 } = useMain()

 const pageCount = Math.ceil(destaques.length / limit)

 const loadDestaques = useCallback(async () => {
  try {
   const response = await api.get('temnoparque-destaques')

   setDestaques(response.data)
  } catch (error) {
   console.log('Ocorreu um erro ao buscar os destaques')
  }
 }, [setDestaques])

 useEffect(() => {
  loadDestaques()
 }, [loadDestaques])

 return (
  <>
   <ToastContainer />

   {modalAdd && (
    <ModalAdd
     isClose={toggleModalAdd}
     isLoad={loadDestaques}
     fields={fields}
     name="temnoparque-destaques"
     title="Destaque"
    />
   )}
   {modalDelete && (
    <ModalDelete
     isClose={toggleModalDelete}
     isLoad={loadDestaques}
     id={id}
     name="temnoparque-destaques"
     title="Destaque"
    />
   )}
   {modalEdit && (
    <ModalEdit
     isClose={toggleModalEdit}
     isLoad={loadDestaques}
     id={id}
     name="temnoparque-destaques"
     folder="temnoparquedestaques"
     title="Destaque"
    />
   )}

   <Header title={title} subtitle={subtitle} />
   <Container>
    <Title>Gerenciador de destaques</Title>
    {destaques.length <= 0 ? (
     <>Nenhum destaque cadastrado.</>
    ) : (
     <>
      <Table>
       <thead>
        <tr>
         <th>Exibição</th>
         <th>Imagem</th>
         <th>Título</th>
         <th>Status</th>
         <th colSpan="2"></th>
        </tr>
       </thead>
       <tbody>
        {destaques &&
         destaques.slice(offset, offset + limit).map(destaque => (
          <tr key={destaque.id}>
           <td>{destaque.id}</td>
           <td>
            <div className="eventos">
             {destaque.file ? (
              <img
               src={`${process.env.REACT_APP_IMAGE_URL}/uploads/temnoparquedestaques/${destaque.file}`}
               alt={destaque.title}
              />
             ) : (
              ''
             )}
            </div>
           </td>
           <td>{destaque.title}</td>
           <td>
            {destaque.status === 1 ? (
             <img src={Checked} alt="Ativo" />
            ) : (
             <img src={Unchecked} alt="Inativo" />
            )}
           </td>
           <td onMouseDown={() => setID(destaque.id)}>
            <img
             src={Edit}
             alt="Editar"
             title="Editar"
             onClick={toggleModalEdit}
            />
           </td>
           <td onMouseDown={() => setID(destaque.id)}>
            <img
             src={Trash}
             alt="Deletar"
             title="Deletar"
             onClick={toggleModalDelete}
            />
           </td>
          </tr>
         ))}
       </tbody>
      </Table>

      <ReactPaginate
       previousLabel={'<'}
       nextLabel={'>'}
       pageCount={pageCount}
       onPageChange={handlePageClick}
       containerClassName={'pagination'}
       previousLinkClassName={'pagination__link'}
       nextLinkClassName={'pagination__link'}
       disabledClassName={'pagination__link--disabled'}
       activeClassName={'pagination__link--active'}
      />
     </>
    )}
   </Container>

   <Container>
    <Title>Especificações do banner</Title>
    <Content>
     <div>
      <img src={banner4} alt="Mini banners" />
      <h3>Mini Banners</h3>
      <p>Formato: 468 x 320 pixels</p>
      <p>Tamanho: Máximo 1MB</p>
     </div>
    </Content>
   </Container>

   <Botao isOpen={toggleModalAdd} />
  </>
 )
}
