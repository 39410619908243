import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'

import api from '../../services/api'

import logo from '../../assets/logo.png'

import { Container, Background, Form } from './styles'

export function Login() {
 const [login, setLogin] = useState('')
 const [password, setPassword] = useState('')
 const [loading, setLoading] = useState(false)
 const history = useHistory()

 async function handleFormLogin(e) {
  e.preventDefault()

  try {
   const dataForm = {
    login,
    password
   }

   setLoading(true)

   const response = await api.post('/login', dataForm)
   const data = await response.data
   const token = await response.data.token

   if (token) {
    localStorage.setItem('token', token)
    api.defaults.headers.Authorization = `Bearer ${token}`

    history.push('/home')
   } else {
    toast.error(data, {
     position: 'top-right',
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined
    })

    setLoading(false)

    history.push('/')
   }
  } catch (error) {
   console.error(error)
  }
 }

 useEffect(() => {
  return () => {
   setLoading(false)
  }
 }, [])

 return (
  <>
   <ToastContainer />

   <Background>
    <Container>
     <Form onSubmit={handleFormLogin}>
      <img src={logo} alt="Shopping Parque da Cidade" />

      <input
       placeholder="Login"
       type="text"
       name="login"
       value={login}
       onChange={e => setLogin(e.target.value)}
      />

      <input
       placeholder="Senha"
       type="password"
       name="password"
       value={password}
       onChange={e => setPassword(e.target.value)}
      />

      <button type="submit">{loading ? 'Carregando...' : 'Entrar'}</button>
     </Form>
    </Container>
   </Background>
  </>
 )
}
