import { List } from './styles';
import { Link } from 'react-router-dom';

import menu1 from '../../assets/AlterarBanner.svg';
import menu2 from '../../assets/Listar.svg';


export function MenuClubeDoParque(){
  return (
    <>
      <List>
        <Link to="/clube-do-parque-banner">
          <img src={menu1} alt="Alterar Banner" />
          <p>Alterar Banner</p>
        </Link>
      </List>
      <List>
        <Link to="/clube-do-parque-lista-eventos">
          <img src={menu2} alt="Lista de Benefícios" />
          <p>Lista de Benefícios</p>
        </Link>
      </List>
    </>
    
  );
}