import { useState, useEffect } from 'react'

import api from '../../services/api'

export function BannerTemNoParque() {
 const [banners, setBanners] = useState([])

 useEffect(() => {
  async function loadBanners() {
   const response = await api.get('banners-temnoparque')
   setBanners(response.data)
  }
  loadBanners()
 }, [])

 return (
  <>
   {banners.map(banner => (
    <option key={banner.id} value={banner.id}>
     {banner.title}
    </option>
   ))}
  </>
 )
}
