import { Header } from "../../components/Header";
import { MenuPaginaInicial } from "../../components/MenuPaginaInicial";

import { Container } from './styles';

export function PaginaInicial() {
  
  const title = "Página Inicial";

  return (
    <>
      <Header title={title} />

      <Container>
        <MenuPaginaInicial />
      </Container>
      
    </>
  );
}