import { List } from './styles';
import { Link } from 'react-router-dom';

import menu1 from '../../assets/AlterarBanner.svg';
import menu2 from '../../assets/ConteudoPagina.svg';


export function MenuCinema(){
  return (
    <>
      <List>
        <Link to="/cinema-banner">
          <img src={menu1} alt="Alterar Banner" />
          <p>Alterar Banner</p>
        </Link>
      </List>
      <List>
        <Link to="/cinema-conteudo">
          <img src={menu2} alt="Conteúdo da Página" className="conteudo" />
          <p>Conteúdo da Página</p>
        </Link>
      </List>
    </>
    
  );
}