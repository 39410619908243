import { Header } from "../../components/Header";
import { MenuAconteceNoParque } from "../../components/MenuAconteceNoParque";

import { Container } from './styles';

export function AconteceNoParque(props) {

  const title = "Acontece no Parque";

  return (
    <>
      <Header title={title} />

      <Container>
        <MenuAconteceNoParque />
      </Container>
      
    </>
  );
}