import { List } from './styles';
import { Link } from 'react-router-dom';

import menu1 from '../../assets/Pagina-Inicial.svg';
import menu2 from '../../assets/Lojas.svg';
import menu3 from '../../assets/Alimentacao.svg';
import menu4 from '../../assets/Cinema.svg';
import menu5 from '../../assets/AconteceNoParque.svg';
import menu6 from '../../assets/TemNoParque.svg';
import menu7 from '../../assets/ClubeDoParque.svg';
import menu8 from '../../assets/Servicos.svg';
import menu9 from '../../assets/Contato.svg';
import menu10 from '../../assets/Listar.svg';
import menu11 from '../../assets/building.png';


export function MenuHome(){
  return (
    <>
      <List>
        <Link to="/pagina-inicial">
          <img src={menu1} alt="Página Inicial" />
          <p>Página Inicial</p>
        </Link>
      </List>
      <List>
        <Link to="/segmentos">
          <img src={menu10} alt="Segmentos" />
          <p>Segmentos</p>
        </Link>
      </List>
      <List>
        <Link to="/lojas">
          <img src={menu2} alt="Lojas" />
          <p>Lojas</p>
        </Link>
      </List>
      <List>
        <Link to="/alimentacao">
          <img src={menu3} alt="Alimentação" />
          <p>Alimentação</p>
        </Link>
      </List>
      <List>
        <Link to="/cinema">
          <img src={menu4} alt="Cinema" />
          <p>Cinema</p>
        </Link>
      </List>
      <List>
        <Link to="/acontece-no-parque">
          <img src={menu5} alt="Acontece no Parque" />
          <p>Acontece no Parque</p>
        </Link>
      </List>
      <List>
        <Link to="/tem-no-parque">
          <img src={menu6} alt="Tem no Parque" />
          <p>Tem no Parque</p>
        </Link>
      </List>
      <List>
        <Link to="/clube-do-parque">
          <img src={menu7} alt="Clube do Parque" />
          <p>Clube do<br /> Parque</p>
        </Link>
      </List>
      <List>
        <Link to="/institucional">
          <img src={menu11} alt="Institucional" />
          <p>institucional</p>
        </Link>
      </List>
      <List>
        <Link to="/servicos">
          <img src={menu8} alt="Serviços" />
          <p>Serviços</p>
        </Link>
      </List>
      <List>
        <Link to="/contato">
          <img src={menu9} alt="Contato" />
          <p>Contato</p>
        </Link>
      </List>
      {/* <List>
        <img src={menu10} alt="Horário de Funcionamento" />
        <p>Horário de Funcionamento</p>
      </List> */}
    </>
    
  );
}