import { createGlobalStyle } from 'styled-components'; 

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #f5f5f5;
    --white-bg: #ffffff;
    --green: #63a269;
    --title: #63a269;
    --subtitle: #454b4b;
    --white: #ffffff;
    --black: #3F4C50;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }
  
  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .conteudo {
    width: 8.1rem !important;
  }

  .Toastify {
    z-index: 999999;
    position: absolute;
  }

  .Toastify__toast--warning {
    color: var(--black);
    font-weight: 700;
  }

  .Toastify__toast-body {
    font-size: 0.9rem;
  }

  .slideIcon img {
    width: auto !important;
  }

  //Pagination
  .pagination {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 2rem 0 0 0;
    color: #3F4C50;
    font-size: 0.9rem;
  }

  .pagination li {
    //padding: 0px 0.7rem;
    border: none;
    padding: 0.25rem;
    min-width: 2.75rem;
    height: 2.75rem;
    line-height: 2.5rem;
    text-shadow: none;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    a {
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;

      &:focus {
        outline: none;
      }
    }
  }

  .pagination__link--active{
    background: var(--green);
    color: var(--white);
    font-weight: 700;
  }

  .pagination .previous, .next {
    font-weight: 700;
    cursor: pointer;
    
    a {
      &:focus {
        outline: none;
      }
    }
    
  }

  .login {
    padding-bottom: 1.2rem;
    color: var(--green)
  }

  .dateInput {
    
    input {
      padding: 10px;
      cursor: pointer;
      margin-right: 10px;
      border: 1px solid var(--green);
    }

  }

  ::-webkit-calendar-picker-indicator {
   cursor: pointer;
  }

  .listImageGallery {
    list-style: none;
    display: flex;
    gap: 5px
  }

  .listImageGallery li {
    width: 100px;
    height: 100px;
    border: 1px solid var(--green);
  }

  .listImageGallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

`;