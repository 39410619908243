import styled from 'styled-components';

export const ModalBG = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 
    background: #00000066; 
    z-index: 99999;
    overflow-y: auto;
`;

export const ModalContainer = styled.div`
    position: relative; 
    top: 25px; 
    width: 330px; 
    height: auto; 
    background: var(--white-bg); 
  
    margin: 0 auto;

    span {
        text-align: right;
        display: block;

        svg {
            cursor: pointer;
        }
    }
`;

export const Title = styled.h2`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 0.9rem;
    color: var(--green);
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.8rem;
    padding: 1rem 0.7rem;

    svg {
        cursor: pointer;
    }
`;

export const Subtitle = styled.h3`
   

    font-size: 1rem;
    color: var(--subtitle);
    font-weight: 400;
    text-transform: none;
    line-height: 1.4rem;
    padding: 2rem 0.7rem;
    text-align: center;

    svg {
        cursor: pointer;
    }
`;

export const Content = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;

    div{
        padding: 0 0.7rem 1rem 0.7rem;
        font-size: 0.9rem;
    }

    .btn-submit {
        margin: 3rem 0 0 0;
    }

    
`;


export const ModalButton = styled.button`
    
    color: var(--white);

    width: 100%;
    border: 0;
    height: 3rem;

    font-size: 1rem;
    font-weight: 700;

    transition: filter 0.2s;

    &:hover {
        filter: brightness(0.9)
    }

    &.cancelar {
        background: #c1c1c1;
    }

    &.deletar {
        background: #f15e5e !important;
    }
`;
