import { useEffect, useMemo, useCallback } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from 'react-paginate';
import { useMain } from '../../contexts/MainContext';

import api from "../../services/api";

import { Header } from "../../components/Header";
import { ModalAdd } from "../../components/ModalAdd";
import { ModalEdit } from "../../components/ModalEdit";
import { ModalDelete } from "../../components/ModalDelete";
import { Botao } from "../../components/Botao";

import Edit from '../../assets/edit.svg';
import Trash from '../../assets/trash.svg';

import { Container, Title, Table } from './styles';

export function Usuarios() {

    const title = "Usuários";

    const fields = useMemo(() => 
        [
            {
               id: 'Usuário',
               name: String,
               login: String,
               password: String
            }
        ],
        []
    );

    const { 
        users, 
        setUsers,
        modalAdd,  
        toggleModalAdd,
        modalEdit,
        toggleModalEdit,
        modalDelete,
        toggleModalDelete,
        id,
        setID,
        offset, 
        limit, 
        handlePageClick 
    } = useMain();

    const pageCount = Math.ceil(users.length / limit);
        
    const loadUsers = useCallback( async () => {
        try {
            const response = await api.get('users');

            setUsers(response.data);    

        } catch (error) {
            console.log("Ocorreu um erro ao buscar os usuários cadastrados");
        }
        
    }, [setUsers])


    useEffect(() => { 
        loadUsers();
    }, [loadUsers]);

    return (
        <>

            <ToastContainer />

            {modalAdd && (<ModalAdd isClose={toggleModalAdd} isLoad={loadUsers} fields={fields} name="users" title="Usuário" />)}
            {modalDelete && (<ModalDelete isClose={toggleModalDelete} isLoad={loadUsers} id={id} name="users" title="Usuário" />)}
            {modalEdit && (<ModalEdit isClose={toggleModalEdit} isLoad={loadUsers} id={id} name="users" title="Usuário" />)}

            <Header title={title} />
            <Container>
                <Title>Gerenciador de Usuários</Title>
                {users.length <= 0 ? (
                    <>
                        Nenhum usuário cadastrado.
                    </>
                ) : (
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Exibição</th>
                                    <th>Nome</th>
                                    <th>Login</th>
                                    <th colSpan="2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users && (
                                    users.slice(offset, offset + limit).map(user => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.name}</td>
                                            <td>{user.login}</td>                        
                                            <td onMouseDown={() => setID(user.id)}>
                                                <img src={Edit} alt="Editar" title="Editar" onClick={toggleModalEdit} />
                                            </td>
                                            <td onMouseDown={() => setID(user.id)}>
                                                <img src={Trash} alt="Deletar" title="Deletar" onClick={toggleModalDelete} />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>

                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        />
                    </>
                )}
            </Container>

            <Botao isOpen={toggleModalAdd} />

        </>
    );
}
