import styled, { css } from 'styled-components'

const dragActive = css`
 border-color: #78e5d5;
`

const dragReject = css`
 border-color: #e57878;
`

export const ThumbsContainer = styled.aside`
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 margin-top: 16px;
 position: absolute;
 top: 0;
 left: 10px;
 z-index: 2;
`

export const Thumb = styled.div`
 display: inline-flex;
 border-radius: 2px;
 border: 1px solid var(--green);
 margin-bottom: 8px;
 margin-right: 8px;
 width: 100px;
 height: 100px;
 padding: 0 !important;
 box-sizing: border-box;
`

export const ThumbInner = styled.div`
 display: flex;
 min-width: 0;
 padding: 0 !important;
 overflow: hidden;
`

export const Img = styled.img`
 display: block;
 width: auto;
 height: 100%;
`

export const DropContainer = styled.div.attrs({
 className: 'dropzone'
})`
 border: 1px dashed var(--green);
 border-radius: 4px;
 cursor: pointer;
 transition: height 0.2s ease;
 padding: 0 !important;
 position: relative;
 z-index: 1;
 width: 100%;
 height: 16.5rem;
 ${props => props.isDragActive && dragActive};
 ${props => props.isDragReject && dragReject};
 &:focus {
  outline: none;
 }

 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
 }
`

const messageColors = {
 default: '#63a269',
 error: '#e57878',
 success: '#78e5d5'
}

export const UploadMessage = styled.p`
 display: flex;
 color: ${props => messageColors[props.type || 'default']};
 justify-content: center;
 align-items: center;
 height: 100%;
`
