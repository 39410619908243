import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import {
 DropContainer,
 UploadMessage,
 ThumbsContainer,
 Thumb,
 ThumbInner,
 Img
} from './styles'

export function UploadGallery(props) {
 const [images, setImages] = useState([])
 const [uploads, setUploads] = useState([])
 const { onUpload } = props

 const onDrop = useCallback(acceptedFiles => {
  acceptedFiles.forEach(file => {
   const reader = new FileReader()
   reader.onload = () => {
    setImages(prev => [...prev, reader.result])
    //setPaths(prev => [...prev, file])
   }
   reader.readAsDataURL(file)
  setUploads(prev => [...prev, file])
  })  
  
 }, [])

  useEffect(() => {
    //onUpload(images.join('-'))
    onUpload(uploads)
  }, [onUpload, uploads])

 const { getRootProps, getInputProps, isDragActive, isDragReject } =
  useDropzone({ onDrop, accept: 'image/*' })

 const thumbs = images.map((image, index) => (
  <Thumb key={index}>
   <ThumbInner>
    <Img src={image} />
   </ThumbInner>
  </Thumb>
 ))

 const renderDragMessage = (isDragActive, isDragReject) => {
  if (!isDragActive) {
   return <UploadMessage>Arraste e jogue ou clique aqui</UploadMessage>
  }

  if (isDragReject) {
   return <UploadMessage type="error">Arquivo não suportado</UploadMessage>
  }

  return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
 }

 return (
  <DropContainer
   {...getRootProps({ className: 'dropzone' })}
   isDragActive={isDragActive}
   isDragReject={isDragReject}
  >
   <input {...getInputProps()}  />
   {isDragActive
    ? renderDragMessage(isDragActive)
    : renderDragMessage(isDragReject)}
   <ThumbsContainer>{thumbs}</ThumbsContainer>
  </DropContainer>
 )
}
