import { List } from './styles';
import { Link } from 'react-router-dom';

import menu1 from '../../assets/AlterarBanner.svg';
import menu2 from '../../assets/ConteudoPagina.svg';
import menu3 from '../../assets/HorarioDeFuncionamento.svg';


export function MenuInstitucional(){
  return (
    <>
      <List>
        <Link to="/institucional-banner">
          <img src={menu1} alt="Alterar Banner" />
          <p>Alterar Banner</p>
        </Link>
      </List>
      <List>
        <Link to="/institucional-conteudo">
          <img src={menu2} alt="Conteúdo da Página" className="conteudo" />
          <p>Conteúdo da Página</p>
        </Link>
      </List>
      <List>
        <Link to="/institucional-horarios">
          <img src={menu3} alt="Horário de Funcionamento" />
          <p>Horário de Funcionamento</p>
        </Link>
      </List>
    </>
    
  );
}