import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1600px;
  margin: 2rem 0 2rem 15rem;
  padding: 1.5rem;

  background: var(--white);
  border-radius: 0.25rem;
`;


export const Title = styled.h2`
    font-size: 0.9rem;
    color: var(--green);
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.4rem;
    padding: 0 0 1.5rem 0;
`;


export const Table = styled.table`
    width: 100%;
    border-spacing: 0rem;
    border: 1px solid #D8D8D8;

    thead {
      tr {
        background: #C1C1C1;
      }
    }

    th {
      color: var(--white);
      font-size: 0.6rem;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      padding: 0.5rem;
    }

    tr {
        color: var(--black);
        font-size: 1.1rem;
        font-weight: 600;
        text-align: center;
        border-right: 1px solid #D8D8D8;

        &:nth-child(even){
            background: #F5F5F5;
        }

        td {
            padding: 0.8rem;
            border-right: 1px solid #D8D8D8;

            &:last-of-type{
                border: 0;
            }

            div {
                border: 1px solid #D8D8D8;
                background: var(--white);
                height: 3rem;
                padding: 3rem;
                position: relative;

            }
        }
       
    }
`;






