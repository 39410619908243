import { useEffect, useMemo, useCallback } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReactPaginate from 'react-paginate'
import { useMain } from '../../contexts/MainContext'

import api from '../../services/api'

import { Header } from '../../components/Header'
import { ModalAdd } from '../../components/ModalAdd'
import { ModalEdit } from '../../components/ModalEdit'
import { ModalDelete } from '../../components/ModalDelete'
import { Botao } from '../../components/Botao'

import Edit from '../../assets/edit.svg'
import Checked from '../../assets/checked.svg'
import Unchecked from '../../assets/unchecked.svg'
import Trash from '../../assets/trash.svg'

import banner1 from '../../assets/banner1.png'
import banner2 from '../../assets/banner4.png'

import { Container, Title, Content, Table } from './styles'

export function AconteceNoParqueBanner() {
 const title = 'Acontece no Parque'
 const subtitle = 'Alterar Banner'

 const fields = useMemo(
  () => [
   {
    id: 'Acontece No Parque Banner',
    imagem: String,
    imagemMobile: String,
    title: String,
    status: Number
   }
  ],
  []
 )

 const {
  bannersAconteceNoParque,
  setBannersAconteceNoParque,
  modalAdd,
  toggleModalAdd,
  modalEdit,
  toggleModalEdit,
  modalDelete,
  toggleModalDelete,
  id,
  setID,
  offset,
  limit,
  handlePageClick
 } = useMain()

 const pageCount = Math.ceil(bannersAconteceNoParque.length / limit)

 const loadBannersAconteceNoParque = useCallback(async () => {
  try {
   const response = await api.get('banners-acontece')

   setBannersAconteceNoParque(response.data)
  } catch (error) {
   console.log('Ocorreu um erro ao buscar os banners')
  }
 }, [setBannersAconteceNoParque])

 useEffect(() => {
  loadBannersAconteceNoParque()
 }, [loadBannersAconteceNoParque])

 return (
  <>
   <ToastContainer />

   {modalAdd && (
    <ModalAdd
     isClose={toggleModalAdd}
     isLoad={loadBannersAconteceNoParque}
     fields={fields}
     name="banners-acontece"
     title="Banner"
    />
   )}
   {modalDelete && (
    <ModalDelete
     isClose={toggleModalDelete}
     isLoad={loadBannersAconteceNoParque}
     id={id}
     name="banners-acontece"
     title="Banner"
    />
   )}
   {modalEdit && (
    <ModalEdit
     isClose={toggleModalEdit}
     isLoad={loadBannersAconteceNoParque}
     id={id}
     name="banners-acontece"
     folder="bannersAconteceNoParque"
     title="Banner"
    />
   )}

   <Header title={title} subtitle={subtitle} />
   <Container>
    <Title>Gerenciador de banners</Title>
    {bannersAconteceNoParque.length <= 0 ? (
     <>Nenhum banner cadastrado.</>
    ) : (
     <>
      <Table>
       <thead>
        <tr>
         <th>Exibição</th>
         <th>Imagem</th>
         <th>Imagem Mobile</th>
         <th>Título</th>
         <th>Status</th>
         <th colSpan="2"></th>
        </tr>
       </thead>
       <tbody>
        {bannersAconteceNoParque &&
         bannersAconteceNoParque.slice(offset, offset + limit).map(banner => (
          <tr key={banner.id}>
           <td>{banner.id}</td>
           <td>
            <div>
             {banner.file ? (
              <img
               src={`${process.env.REACT_APP_IMAGE_URL}/uploads/bannersAconteceNoParque/${banner.file}`}
               alt={banner.title}
              />
             ) : (
              ''
             )}
            </div>
           </td>
           <td>
            <div>
             {banner.file_mobile ? (
              <img
               src={`${process.env.REACT_APP_IMAGE_URL}/uploads/bannersAconteceNoParque/${banner.file_mobile}`}
               alt={banner.title}
              />
             ) : (
              ''
             )}
            </div>
           </td>
           <td>{banner.title}</td>
           <td>
            {banner.status === 1 ? (
             <img src={Checked} alt="Ativo" />
            ) : (
             <img src={Unchecked} alt="Inativo" />
            )}
           </td>
           <td onMouseDown={() => setID(banner.id)}>
            <img
             src={Edit}
             alt="Editar"
             title="Editar"
             onClick={toggleModalEdit}
            />
           </td>
           <td onMouseDown={() => setID(banner.id)}>
            <img
             src={Trash}
             alt="Deletar"
             title="Deletar"
             onClick={toggleModalDelete}
            />
           </td>
          </tr>
         ))}
       </tbody>
      </Table>

      <ReactPaginate
       previousLabel={'<'}
       nextLabel={'>'}
       pageCount={pageCount}
       onPageChange={handlePageClick}
       containerClassName={'pagination'}
       previousLinkClassName={'pagination__link'}
       nextLinkClassName={'pagination__link'}
       disabledClassName={'pagination__link--disabled'}
       activeClassName={'pagination__link--active'}
      />
     </>
    )}
   </Container>

   <Container>
    <Title>Especificações do banner</Title>
    <Content>
     <div>
      <img src={banner1} alt="Banner principal" />
      <h3>Banner Principal</h3>
      <p>Formato: 1920 x 650 pixels</p>
      <p>Tamanho: Máximo 1MB</p>
     </div>
     <div>
      <img src={banner2} alt="Banner principal Mobile" />
      <h3>Banner Principal Mobile</h3>
      <p>Formato: 570 x 375 pixels</p>
      <p>Tamanho: Máximo 1MB</p>
     </div>
    </Content>
   </Container>

   <Botao isOpen={toggleModalAdd} />
  </>
 )
}
