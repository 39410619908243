import { useState, useEffect } from 'react'

import api from '../../services/api'

export function TemNoParquePages() {
 const [pages, setPages] = useState([])

 useEffect(() => {
  async function loadPages() {
   const response = await api.get('temnoparque')
   setPages(response.data)
  }
  loadPages()
 }, [])

 return (
  <>
   {pages.map(page => (
    <option key={page.id} value={page.id}>
     {page.page}
    </option>
   ))}
  </>
 )
}
