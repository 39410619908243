import { createContext, useContext, useState } from 'react'

export const MainContext = createContext()

export function MainContextProvider({ children }) {
 const [slides, setSlides] = useState([])
 const [banners, setBanners] = useState([])
 const [bannersLoja, setBannersLoja] = useState([])
 const [bannersGastronomia, setBannersGastronomia] = useState([])
 const [bannersCinema, setBannersCinema] = useState([])
 const [bannersAconteceNoParque, setBannersAconteceNoParque] = useState([])
 const [bannersTemNoParque, setBannersTemNoParque] = useState([])
 const [bannersClubeDoParque, setBannersClubeDoParque] = useState([])
 const [bannersInstitucional, setBannersInstitucional] = useState([])
 const [lojas, setLojas] = useState([])
 const [restaurantes, setRestaurantes] = useState([])
 const [filmes, setFilmes] = useState([])
 const [eventos, setEventos] = useState([])
 const [destaques, setDestaques] = useState([])
 const [galerias, setGalerias] = useState([])
 const [beneficios, setBeneficios] = useState([])
 const [servicos, setServicos] = useState([])
 const [contato, setContato] = useState([])
 const [horarios, setHorarios] = useState([])
 const [users, setUsers] = useState([])
 const [contentCinema, setContentCinema] = useState([])
 const [contentEventos, setContentEventos] = useState([])
 const [contentTemNoParque, setContentTemNoParque] = useState([])
 const [contentInstitucional, setContentInstitucional] = useState([])
 const [segmentos, setSegmentos] = useState([])
 const [modalAdd, setModalAdd] = useState(false)
 const [modalEdit, setModalEdit] = useState(false)
 const [modalDelete, setModalDelete] = useState(false)
 const [id, setID] = useState('')

 //Pagination
 const [currentPage, setCurrentPage] = useState(0)

 const limit = 10
 const offset = currentPage * limit

 const handlePageClick = ({ selected: selectedPage }) => {
  setCurrentPage(selectedPage)
 }

 function toggleModalAdd() {
  setModalAdd(!modalAdd)
 }

 function toggleModalEdit() {
  setModalEdit(!modalEdit)
 }

 function toggleModalDelete() {
  setModalDelete(!modalDelete)
 }

 return (
  <MainContext.Provider
   value={{
    slides,
    setSlides,
    banners,
    setBanners,
    bannersLoja,
    setBannersLoja,
    bannersGastronomia,
    setBannersGastronomia,
    bannersCinema,
    setBannersCinema,
    bannersAconteceNoParque,
    setBannersAconteceNoParque,
    bannersTemNoParque,
    setBannersTemNoParque,
    bannersClubeDoParque,
    setBannersClubeDoParque,
    bannersInstitucional,
    setBannersInstitucional,
    lojas,
    setLojas,
    restaurantes,
    setRestaurantes,
    filmes,
    setFilmes,
    eventos,
    setEventos,
    destaques,
    setDestaques,
    galerias,
    setGalerias,
    beneficios,
    setBeneficios,
    servicos,
    setServicos,
    contato,
    setContato,
    horarios,
    setHorarios,
    users,
    setUsers,
    contentCinema,
    setContentCinema,
    contentEventos,
    setContentEventos,
    contentTemNoParque,
    setContentTemNoParque,
    contentInstitucional,
    setContentInstitucional,
    segmentos,
    setSegmentos,
    id,
    setID,
    offset,
    limit,
    handlePageClick,
    modalAdd,
    toggleModalAdd,
    modalEdit,
    toggleModalEdit,
    modalDelete,
    toggleModalDelete
   }}
  >
   {children}
  </MainContext.Provider>
 )
}

export const useMain = () => {
 return useContext(MainContext)
}
