import { List } from './styles';
import { Link } from 'react-router-dom';

import menu1 from '../../assets/AlterarBanner.svg';
import menu2 from '../../assets/Listar.svg';


export function MenuLojas(){
  return (
    <>
      <List>
        <Link to="/lojas-banner">
          <img src={menu1} alt="Alterar Banner" />
          <p>Alterar Banner</p>
        </Link>
      </List>
      <List>
        <Link to="/lista-de-lojas">
          <img src={menu2} alt="Lista de Lojas" />
          <p>Lista de Lojas</p>
        </Link>
      </List>
    </>
    
  );
}