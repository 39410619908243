import { Header } from "../../components/Header";
import { MenuTemNoParque } from "../../components/MenuTemNoParque";

import { Container } from './styles';

export function TemNoParque(props) {

  const title = "Tem no Parque";

  return (
    <>
      <Header title={title} />

      <Container>
        <MenuTemNoParque />
      </Container>
      
    </>
  );
}