import { useEffect, useMemo, useCallback } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReactPaginate from 'react-paginate'
import { useMain } from '../../contexts/MainContext'

import api from '../../services/api'

import { Header } from '../../components/Header'
import { ModalAdd } from '../../components/ModalAdd'
import { ModalEdit } from '../../components/ModalEdit'
import { ModalDelete } from '../../components/ModalDelete'
import { Botao } from '../../components/Botao'

import Edit from '../../assets/edit.svg'
import Checked from '../../assets/checked.svg'
import Unchecked from '../../assets/unchecked.svg'
import Trash from '../../assets/trash.svg'

import banner4 from '../../assets/banner4.png'

import { Container, Title, Content, Table } from './styles'

export function ListaLojas() {
 const title = 'Lojas'
 const subtitle = 'Lista de Lojas'

 const fields = useMemo(
  () => [
   {
    id: 'Lojas',
    imagem: String,
    name: String,
    category: String,
    subcategory: String,
    subcategory2: String,
    text: String,
    floor: String,
    phone: String,
    site: String,
    instagram: String,
    facebook: String,
    whatsapp: String,
    status: Number
   }
  ],
  []
 )

 const {
  lojas,
  setLojas,
  modalAdd,
  toggleModalAdd,
  modalEdit,
  toggleModalEdit,
  modalDelete,
  toggleModalDelete,
  id,
  setID,
  offset,
  limit,
  handlePageClick
 } = useMain()

 const pageCount = Math.ceil(lojas.length / limit)

 const loadLojas = useCallback(async () => {
  try {
   const response = await api.get('lojas')

   setLojas(response.data)
  } catch (error) {
   console.log('Ocorreu um erro ao buscar as lojas')
  }
 }, [setLojas])

 useEffect(() => {
  loadLojas()
 }, [loadLojas])

 return (
  <>
   <ToastContainer />

   {modalAdd && (
    <ModalAdd
     isClose={toggleModalAdd}
     isLoad={loadLojas}
     fields={fields}
     name="lojas"
     title="Loja"
    />
   )}
   {modalDelete && (
    <ModalDelete
     isClose={toggleModalDelete}
     isLoad={loadLojas}
     id={id}
     name="lojas"
     title="Loja"
    />
   )}
   {modalEdit && (
    <ModalEdit
     isClose={toggleModalEdit}
     isLoad={loadLojas}
     id={id}
     name="lojas"
     folder="lojas"
     title="Loja"
    />
   )}

   <Header title={title} subtitle={subtitle} />
   <Container>
    <Title>Gerenciador de lojas</Title>
    {lojas.length <= 0 ? (
     <>Nenhuma loja cadastrada.</>
    ) : (
     <>
      <Table>
       <thead>
        <tr>
         <th>Exibição</th>
         <th>Nome da loja</th>
         <th>Imagem</th>
         <th>Categoria</th>
         <th>Piso</th>
         <th>Telefone</th>
         <th>Site</th>
         <th>Status</th>
         <th colSpan="2"></th>
        </tr>
       </thead>
       <tbody>
        {lojas &&
         lojas.slice(offset, offset + limit).map(loja => (
          <tr key={loja.id}>
           <td>{loja.id}</td>
           <td>{loja.name}</td>
           <td>
            <div className="lojas-logo">
             {loja.file ? (
              <img
               src={`${process.env.REACT_APP_IMAGE_URL}/uploads/lojas/${loja.file}`}
               alt={loja.name}
              />
             ) : (
              ''
             )}
            </div>
           </td>
           <td>{loja.category}</td>
           <td>{loja.floor}</td>
           <td>{loja.phone}</td>
           <td>{loja.site}</td>
           <td>
            {loja.status === 1 ? (
             <img src={Checked} alt="Ativo" />
            ) : (
             <img src={Unchecked} alt="Inativo" />
            )}
           </td>
           <td onMouseDown={() => setID(loja.id)}>
            <img
             src={Edit}
             alt="Editar"
             title="Editar"
             onClick={toggleModalEdit}
            />
           </td>
           <td onMouseDown={() => setID(loja.id)}>
            <img
             src={Trash}
             alt="Deletar"
             title="Deletar"
             onClick={toggleModalDelete}
            />
           </td>
          </tr>
         ))}
       </tbody>
      </Table>

      <ReactPaginate
       previousLabel={'<'}
       nextLabel={'>'}
       pageCount={pageCount}
       onPageChange={handlePageClick}
       containerClassName={'pagination'}
       previousLinkClassName={'pagination__link'}
       nextLinkClassName={'pagination__link'}
       disabledClassName={'pagination__link--disabled'}
       activeClassName={'pagination__link--active'}
      />
     </>
    )}
   </Container>

   <Container>
    <Title>Especificações dos logos</Title>
    <Content>
     <div>
      <img src={banner4} alt="Logo" />
      <h3>Logo</h3>
      <p>Formato: 500 x 500 pixels</p>
     </div>
    </Content>
   </Container>

   <Botao isOpen={toggleModalAdd} />
  </>
 )
}
