import { List } from './styles';
import { Link } from 'react-router-dom';

import menu1 from '../../assets/AlterarSlides.png';
import menu2 from '../../assets/AlterarBanner.svg';


export function MenuPaginaInicial(){
  return (
    <>
      <List className="slideIcon">
        <Link to="/slides">
          <img src={menu1} alt="Alterar Slides Home" />
          <p>Alterar Slides</p>
        </Link>
      </List>
      <List>
        <Link to="/banners">
          <img src={menu2} alt="Alterar Banners Home" />
          <p>Banners</p>
        </Link>
      </List>
    </>
    
  );
}